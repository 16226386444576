import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
// import Box from 'components/box';
// import Title from 'components/title';
// import Gallery from 'components/gallery';
// import IOExample from 'components/io-example';
import HeaderJumbotron from 'components/headerJumbotron';
import BookingInputBox from 'components/bookingInputBox';
import IconSection from 'components/iconSection';
import ZetaSection from 'components/zetaSection';
import RoomsBox from 'components/roomsBox';
import MapsSection from 'components/mapsSection';
import Footer from 'components/footer';
import { Container, Row, Col } from 'reactstrap';
// import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image';

import { graphql } from 'gatsby';
const Index = ({ data }) => (
  <Layout>
    <HeaderJumbotron jumboImage={data.zeta} headerText="Comfortable stay, at the heart of Mumbai" />
    <BookingInputBox />
    {/* <IconSection /> */}
    <Container style={{ marginTop: '2.5rem' }}>
          <h1 style={{ textAlign: 'center', fontFamily: 'Aller' }}>
            Zeta - Party, Banquet & Conference Hall In Bandra West
          </h1>
          <Container style={{margin:"7%",width:"85%"}}>

            <p style={{fontWeight:"bold"}}>
              Looking for a banquet, party hall or conference hall for guests from Khar, SantaCruz, Vile Parle, Andheri, Mahim, Worli and other parts of Mumbai?
              </p>
              <br />
              <p>
Zeta Banquet and conference hall offers plenty of space ideal for hosting all kinds of events and is perfectly located in Bandra West to serve all of your guests

With the location being at the center of the city, its proximity to Western Express Highway and Bandra Terminus railway station makes it ideal for guests to reach the venue and return. For guests coming from far away, they can nest away at Hotel Grace Galaxy without having to travel to another hotel. 
</p>
<br />
<p>
  Zeta can be used as a
  <ul>
<li>Banquet hall for <b>birthday parties</b> in bandra west</li>
<li>Banquet hall for <b>family events, baby showers and get togethers</b> in bandra west</li>
<li>Banquet hall for club or <b>group events like kitty parties</b> in bandra west</li>
<li>Banquet hall for <b>Mehindi, Haldi, Engagement and ring ceremony and other pre-wedding events</b> in bandra west</li>
<li>Banqueting and Conference Hall for <b>corporate events</b> in bandra west</li>
<li>Hall for <b>seminars, workshops and meetings</b> in bandra west</li>
  </ul>
</p>
<br />
<p>
  With a space of 1200 square feet, Zeta Hall has a <b>max capacity of 100 People</b>. 
  <br />
The <b>decoration</b> for the events is taken care of by the staff at the venue. 
<br />
Scrumptious food is served as per your booking(<b>Veg or Non Veg</b>) with the help of in-house catering services. 
Zeta @ Hotel Grace Galaxy Bandra, Mumbai is always open for events with ambience like no other.
</p>
<br />

<h2> 
For Enquiries about Zeta - Party, Banquet & Conference hall in Bandra West Please call +91 7718800522
</h2>


          </Container>
    </Container>
    <center><h3>Need rooms too?</h3></center>
    <RoomsBox />
    <MapsSection />
    <Footer />
  </Layout>
);

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query {
    zeta: file(
      relativePath: { eq: "images/gallery/zeta.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bwsl: file(
      relativePath: { eq: "images/gallery/bandra-worli-sea-link.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    concept: file(relativePath: { eq: "images/gallery/concept.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    file(relativePath: { eq: "images/gallery/bandra-worli-sea-link.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    homeJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      gallery {
        title
        copy
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
